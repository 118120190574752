<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="이벤트" />
				</div>
			</div>
		</div>
		<div class="page-content event-check">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title"><span class="prima">동행축제 이벤트에 참여하고</span> 푸짐한 선물 받으세요.</h3>
				</div>
			</div>
			<div class="page-tab-container">
				<div class="container tab-buttons">
					<router-link to="check" class="tab-button is-active">출석체크</router-link>
					<router-link to="dibs" class="tab-button">찜하기</router-link>
					<router-link to="sns" class="tab-button">구매후기</router-link>
				</div>
			</div>
			<div class="container">
				<div class="event-check-container">
					<div class="event-check-header">
						<div class="event-check-header-banner">
							<div class="banner-titlewrap">
								<span class="banner-title">{{ getAtndTitle }}</span>
								<span class="banner-title">출석체크 이벤트</span>
							</div>
							<div class="banner-img">
								<img src="@/assets/img/event/banner_icon03.jpg" alt="" />
							</div>
						</div>
						<div class="event-check-attendance">
							<div class="attendance-table">
								<table class="table">
									<caption>
										일,월,화,수,목,금,토 항목의 출석체크 표
									</caption>
									<colgroup>
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
									</colgroup>
									<thead>
										<tr class="table-week-header">
											<th v-for="(week, idx) in weekList" :key="idx">
												<div class="table-week-container">
													<span class="week-name">{{ week.name }}</span>
													<span class="week-name-eng">{{ week.eng }}</span>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(week, idx) in dateMatrix" :key="idx" :class="{ 'is-border': week.some((item) => String(item.date).includes('/1')) }">
											<td v-for="date in week" :key="date.day">
												<div v-if="date.day === dateConvert(new Date(), 'yyyy-MM-dd') && typeof date.event === 'undefined'">
													<button type="button" class="button-check-attend" @click.prevent="clickAtndCheckEvent" :value="date.day">출석</button>
												</div>
												<div v-else>
													<div v-if="date.event === undefined" class="day-num">
														{{ date.date }}
													</div>
													<div v-if="date.event" class="day-num-icon">
														<i v-if="date.event.heart" class="icon-attend-heart">
															<span class="sr-only">출석 체크</span>
														</i>
														<i v-else class="icon-attend-check"><span class="sr-only">출석 체크</span></i>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="event-check-header-info">
							<ul class="event-check-header-info-list">
								<li v-for="(item, idx) in infoList" :key="idx">
									<span class="infolist-title">{{ item.title }}</span>
									<span class="infolist-text" v-if="idx === 0">{{ item.detail }}</span>
									<span class="infolist-text" v-else>{{ item.detail | getAddMonthKorFilter(idx) }} 중</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-guide">
						<span class="event-check-guide-title">참여방법</span>
						<div class="event-check-guide-desc">
							<ul class="event-check-guide-desclist">
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img">
										<img src="@/assets/img/event/icon_img06.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 1</span>
										<span class="desclist-text">출석체크 이벤트 참여 시, 본인인증을 통한 로그인은 필수이며 1일 1회 출석가능 합니다.</span>
									</div>
								</li>
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img">
										<img src="@/assets/img/event/icon_img07.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 2</span>
										<span class="desclist-text">상단의 달력에서 당일 날짜를 선택하면 출석 표시가 됩니다.</span>
										<span class="desclist-text"><span class="boldtxt">7일 출석 시, 하트가 1개 생기며 경품 추첨에 응모됩니다.</span> 하트가 많을수록 당첨 확률이 올라갑니다.</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-products">
						<span class="event-check-products-title">경품소개</span>
						<ul class="event-check-products-list">
							<li class="event-check-products-list-li" v-for="(item, idx) in prdList" :key="idx">
								<div class="event-check-products-item">
									<div class="event-check-products-item-img">
										<img :src="require(`@/assets/img/main/${item.img}`)" :alt="item.title" />
									</div>
									<div class="event-check-products-item-title">
										<span class="item-title">{{ item.title }}</span>
										<span class="item-title boldtxt">{{ item.person }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="event-check-prect">
				<div class="container">
					<span class="event-check-prect-title">유의사항</span>
					<ul class="event-check-prect-list">
						<li class="event-check-prect-list-li" v-for="(item, idx) in prectList" :key="idx">
							<span class="prect-list-text">{{ item }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<event-terms-modal :visible="visibleModal" @closeModal="visibleModal = false" @agreeModal="prvcMktAgrePopup" />
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import EventTermsModal from '@/components/content/EventTermsModal';
import { getItems, dateToDateFormat, isSuccess } from '@/assets/js/utils';
import { countDDay } from '@/assets/js/DateUtils';
import { ACT_SET_EVENT_ATND_CHECK, ACT_GET_EVENT_ATND_CHECK_LIST, ACT_SET_PRVC_MKT_AGRC } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';

import { mapGetters } from 'vuex';
export default {
	name: 'EventCheck',
	components: { PageHeader, EventTermsModal },
	data: () => ({
		visibleModal: false,
		selectDay: '',
		params: {
			atndYmd: '',
			atndYyyymm: '',
			campnId: '',
			userId: 0,
		},
		atnData: {
			strtDt: '',
			endDt: '',
			week: '',
			loop: '',
		},
		items: [],
		weekList: [
			{
				name: '일',
				eng: 'SUN',
			},
			{
				name: '월',
				eng: 'MON',
			},
			{
				name: '화',
				eng: 'TUE',
			},
			{
				name: '수',
				eng: 'WED',
			},
			{
				name: '목',
				eng: 'THU',
			},
			{
				name: '금',
				eng: 'FRI',
			},
			{
				name: '토',
				eng: 'SAT',
			},
		],
		heartCount: 7,
		events: [
			// { date: '2024-06-25', check: true, heart: false },
			// { date: '2024-07-02', check: true, heart: false },
			// { date: '2024-07-03', check: true, heart: true },
			// { date: '2024-07-05', check: true, heart: false },
			// { date: '2024-07-06', check: true, heart: false },
		],
		infoList: [
			{
				title: '참여기간',
				detail: '',
			},
			{
				title: '결과발표',
				detail: '',
			},
			{
				title: '경품발송',
				detail: '',
			},
		],
		prdList: [
			{
				img: 'item_image_2.jpg',
				title: '동행제품 선물박스(20만원 상당)',
				person: '(2명)',
			},
			{
				img: 'item_image_3.jpg',
				title: '온누리상품권 5만원',
				person: '(10명)',
			},
			{
				img: 'item_image_4.jpg',
				title: '커피 기프티콘',
				person: '(60명)',
			},
			{
				img: 'item_image_5.jpg',
				title: '아이스크림 쿠폰',
				person: '(100명)',
			},
		],
		prectList: ['휴대폰 인증을 하셔야 참여할 수 있습니다.'
			, '달력의 날짜를 클릭해서 출석 표시가 되어야 이벤트 참여로 인정됩니다.'
			, '당첨 후 경품의 제세 공과금(22%)는 본인이 부담해야 합니다.'
			, '당첨자는 이벤트 종료 후 1개월 이내 공지될 예정입니다.'
			, '당첨자의 잘못된 정보 입력으로 인해 경품 누락이 될 경우 재 발송되지 않습니다.'
			, '해당 이벤트에 14세 미만은 참여할 수 없습니다.'
			, '이벤트 일정 및 경품내역은 추후 주최측의 사정에 따라 변동 가능합니다.'
		],
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
		dateMatrix() {
			let dates = [];
			let currentDate = new Date(this.atnData.strtDt);
			// 시작 날짜부터 종료 날짜까지의 날짜 목록 생성

			while (currentDate <= this.atnData.endDt) {
				//.toString().split('T')[0];
				const dateString = this.dateConvert(currentDate, 'yyyy-MM-dd');

				// 헤당 일자의 이벤트를 찾음
				const event = this.events.find((event) => event.atndYmd === dateString);
				if (Number(currentDate.getDate()) === 1 || dateString === this.dateConvert(this.atnData.strtDt, 'yyyy-MM-dd')) {
					let strMonth = currentDate.getMonth() + 1;
					let strDay = currentDate.getDate();
					dates.push({ day: dateString, date: `${strMonth}/${strDay}`, event });
				} else {
					dates.push({ day: dateString, date: currentDate.getDate(), event });
				}
				currentDate.setDate(currentDate.getDate() + 1);
			}

			// 날짜 목록을 주 단위로 분할
			const weeks = [];
			let week = new Array(7).fill({ date: '', event: null });

			dates.forEach((date, index) => {
				let dateDay = new Date(date.day);
				const dayOfWeek = new Date(dateDay.getFullYear(), dateDay.getMonth(), dateDay.getDate()).getDay();
				week[dayOfWeek] = date;

				// 해당 월의 마지막 날짜 구하기
				const lastDateOfMonth = new Date(dateDay.getFullYear(), dateDay.getMonth() + 1, 0).getDate();

				if (date.date === lastDateOfMonth) {
					const remainingDays = 7 - dayOfWeek - 1;
					for (let i = 0; i < remainingDays; i++) {
						week[dayOfWeek + i + 1] = { date: '', event: null };
					}
					weeks.push(week);
					week = new Array(7).fill({ date: '', event: null });
				} else if (dayOfWeek === 6 || index === dates.length - 1) {
					weeks.push(week);
					week = new Array(7).fill({ date: '', event: null });
				}
			});
			return weeks;
		},
		dateConvert() {
			return (item, format) => {
				return dateToDateFormat(new Date(item), format);
			};
		},
		checkAtnCalendar() {
			return (data) => {
				return this.events.filter((x) => x.atndYmd === data).length > 0 ? true : false;
			};
		},
		getAtndTitle() {
			const strtMonth = this.atnData.strtDt.getMonth() + 1;
			const endMonth = this.atnData.endDt.getMonth() + 1;
			const titleYear = this.atnData.strtDt.getFullYear();
			const titleMonth = strtMonth === endMonth ? strtMonth : strtMonth + '~' + endMonth;
			return `${titleYear}년 ${titleMonth}월`;
		},
	},
	watch: {},
	filters: {
		getDayFilter(val) {
			return val === '' ? '' : new Date(val).getDate();
		},
		getAddMonthKorFilter(val, idx) {
			const date = new Date(val);
			return `${date.getFullYear()}년  ${(date.getMonth() + idx + 1) % 13}월`;
		},
	},
	created() {
		this.initEvent();
	},
	mounted() {},
	methods: {
		initEvent() {
			this.initParams();
			this.getAtndCheck();
		},
		initParams() {
			this.atnData.strtDt = new Date(this.campn.strtDt);
			this.atnData.endDt = new Date(this.campn.endDt);
			this.atnData.week = this.atnData.strtDt.getDay();
			this.atnData.loop = countDDay(this.atnData.strtDt, this.campn.endDt) + this.atnData.week;
			this.params.atndYmd = this.dateConvert(new Date(), 'yyyyMMdd');
			this.params.atndYyyymm = this.dateConvert(new Date(), 'yyyyMMdd')?.substr(0, 6);
			this.params.campnId = this.campn.campnId;
			this.infoList[0].detail = this.dateKorConvert(this.atnData.strtDt) + ' ~ ' + this.dateKorConvert(this.atnData.endDt);
			this.infoList[1].detail = this.atnData.endDt;
			this.infoList[2].detail = this.atnData.endDt;
		},
		clickAtndCheckEvent(e) {
			if (!this.chkLogin('로그인 후 출석 체크할 수 있습니다.')) return false;
			this.selectDay = this.dateConvert(new Date(e.target.value), 'yyyyMMdd');
			//개인정보 동의 후 진행할 수 있도록 수정
			if (this.campn.prvcMktAgreYn === 'N') {
				this.visibleModal = true;
				return false;
			}

			this.setAtndCheck();
		},
		validation() {
			let msg = '';
			let result = false;

			if (this.checkAtnCalendar(this.selectDay)) {
				msg = '출석체크가 완료된 날짜입니다.';
			} else if (!this.selectDay || this.selectDay !== this.params.atndYmd) {
				msg = '출석체크가 가능한 날짜가 아닙니다.';
			} else {
				result = true;
			}
			if (!result) {
				this.showAlert(msg);
			}
			return result;
		},
		showAlert(msg) {
			this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: msg });
		},
		async getAtndCheck() {
			await this.$store.dispatch(`event/${ACT_GET_EVENT_ATND_CHECK_LIST}`, this.params).then((res) => {
				this.events = getItems(res);
				this.setCheckHeartSticker();
			});
		},
		setCheckHeartSticker() {
			let index = 1;
			this.events.forEach((item) => {
				if (index % this.heartCount === 0) {
					item.heart = true;
				} else {
					item.heart = false;
				}
				index++;
			});
		},
		async setAtndCheck() {
			if (this.validation()) {
				//출석체크 진행
				await this.$store.dispatch(`event/${ACT_SET_EVENT_ATND_CHECK}`, this.params).then((res) => {
					this.showAlert(res.result.number === 200 ? '출석체크가 완료되었습니다.' : res.result.number === 500 ? '출석체크를 실패하였습니다.' : res.result.message);
					this.initEvent();
				});
			}
		},
		chkLogin(title) {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: title,
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},

		dateKorConvert(val) {
			const date = new Date(val);
			return `${date.getFullYear()}년  ${('0' + (date.getMonth() + 1)).slice(-2)}월 ${('0' + date.getDate()).slice(-2)}일 (${this.weekList[date.getDay()].name})`;
		},
		async prvcMktAgrePopup() {
			await this.$store.dispatch(`information/${ACT_SET_PRVC_MKT_AGRC}`, { campnId: this.campn.campnId }).then((res) => {
				if (isSuccess(res)) {
					this.campn.prvcMktAgreYn = 'Y';
					this.visibleModal = false;
					this.setAtndCheck();
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
				}
			});
		},
	},
};
</script>
